<template>
    <a @click="goToProfile()">
        <div class="users-list-user-holder">
            <div class="users-list-avatar">
                <img :src="user.avatar('small') ? user.avatar('small').public_url : '/img/profile-placeholder.jpg'"/>
            </div>

            <div class="users-list-name">{{ user.name() }}</div>
            <div class="users-list-type">{{ user.userType.translate('name') }}</div>

            <div class="users-list-active" v-if="user.deactivated_at">
                Inactief<br/>
                {{ presentDate(user.deactivated_at) }}
            </div>
            <div class="users-list-active" v-else-if="user.absent">
                Afwezig
            </div>
            <div class="users-list-active" v-else></div>


            <div class="users-list-document" v-tooltip="'KvK'" :class="user.document('kvk') ? user.document('kvk').status() : 'empty'">
                <Icon :name="(user.document('kvk') ? user.document('kvk').statusIcon() : 'x-mark')" />
            </div>
            <div class="users-list-document" v-tooltip="'Contract'" :class="user.document('contract') ? user.document('contract').status() : 'empty'">
                <Icon :name="(user.document('contract') ? user.document('contract').statusIcon() : 'x-mark')" />
            </div>

            <!-- OVEREENKOMST -->
            <template v-if="user.userType.name === 'freelancer' || user.userType.name === 'temporary_worker'">
                <div class="users-list-document empty" v-if="agreement === null" v-tooltip="'Geen overeenkomst'">
                    <Icon name="x-mark" />
                </div>
                <div class="users-list-document danger" v-else-if="agreement.starts_at.format('YYYY-MM-DD') > today.format('YYYY-MM-DD')" v-tooltip="'Overeenkomst start vanaf ' + agreement.starts_at.format('DD-MM-YYYY')">
                    <Icon :name="(agreement.accepted_at !== null ? 'check' : 'x')" />
                </div>
                <div class="users-list-document good" v-else-if="agreement.starts_at.format('YYYY-MM-DD') <= today.format('YYYY-MM-DD')" v-tooltip="'Overeenkomst actief t/m ' + agreement.ends_at.format('DD-MM-YYYY')">
                    <Icon :name="(agreement.accepted_at !== null ? 'check' : 'x')" />
                </div>
            </template>
            <div class="users-list-document good" v-else>
                &nbsp;
            </div>

        </div>
    </a>
</template>

<script setup>
    import moment from 'moment';
    import { useRouter, useRoute } from 'vue-router';
    import { ref } from 'vue';

    const props = defineProps(['user']);

    const agreement = ref(null);
    const today = ref(moment());

    const router = useRouter();

    const goToProfile = function() {
        router.push('/users/' + props.user.id);
    }

    const presentDate = function(date)
    {
        return moment(date).format('DD-MM-YYYY');
    }
    const agreements = _.orderBy(props.user.agreements.all(), 'starts_at');

    for (const agreement of agreements) {
        if (
            agreement.ends_at.format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')
        ) {
            agreement.value = agreement;
            break;
        }
    }

</script>
