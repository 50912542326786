<template>
    <div v-for="user of toLongDeactivedUsers" class="alert alert-info">
        <Icon name="trash" type="solid" />
        <template v-if="! authUser.can('users')">{{ user.name() }}</template>
        <a v-else @click="$router.push('/users/' + user.id )">{{ user.name() }}</a> is meer dan 7 jaar geleden gedeactiveerd.
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import { collect } from "collect.js";

    /*
    |--------------------------------------------------------------------------
    | Deactivated users
    |--------------------------------------------------------------------------
    */
    import userServiceNew from '@/services/http/user-service-new.js';

    const toLongDeactivedUsers = ref(collect());

    // Get deactivated users.
    const getDeactivatedUsers = function(){
        return userServiceNew.get({
            filters: {'deactivated_for': 7}
        }).then(userModels => {
            toLongDeactivedUsers.value = userModels;
        });
    }

    // Refresh data and initiate get data.
    const getData = function() {getDeactivatedUsers();}
    getData();
    defineExpose({getData});

    /*
    |--------------------------------------------------------------------------
    | Auth user
    |--------------------------------------------------------------------------
    */
    import { useAuthStore } from "../../stores/auth";
    const authStore = useAuthStore();
    const authUser = computed(() => authStore.user);
</script>
